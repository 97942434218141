body {
  max-width: 100vw;
  overflow-x: hidden;
  background-color: #282c34;
}

.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  color: white;
}

div#loader {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

div#loader :first-child {
  animation-name: spin;
  animation-duration: 5000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  transform-origin: center center;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.sortable-chosen {
  box-sizing: border-box;
  border: 2px dashed white;
}

.how-to {
  padding: 50px;
  position: relative;
  width: 100%;
  max-width: 900px;
  box-sizing: border-box;
}

.how-to-group {
  display: flex;
  align-items: center;
  justify-content: stretch;
}

.how-to-group p {
  margin-top: 0;
}

.group-icon {
  padding: 15px;
}

.group-icon svg {
  width: 45px;
  height: 45px;
}

@media only screen and (max-width: 550px) {
  .how-to {
    padding: 10px;
  }
}
